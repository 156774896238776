import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.3.49518" />
      <meta name="build-date" content="2025-01-24T18:27:19Z" />
    </Head>
  );
};

export default Versioning;
