import { useQuery } from 'react-query';

import userService, { UserUrls } from 'api/services/user';
import {
  ApplicationState,
  ApplicationStateResult,
  ApplicationStatus,
} from 'components/dashboard/applicationTracker/applicationTracker.types';

const useApplicationStatus = () => {
  const fetchApplicationStatus = async () => {
    const res = await userService.GetCustomerApplicationStatus();
    //First result from response array is the latest status
    return GetApplicationStateFromStatus(res[0]);
  };

  const { data, isLoading, isError, isSuccess } = useQuery<ApplicationStateResult>(
    UserUrls.getCustomerApplicationStatus,
    fetchApplicationStatus,
    { staleTime: 60000 },
  );

  return { data, isLoading, isError, isSuccess };
};

export default useApplicationStatus;

export function GetApplicationStateFromStatus(applicationStatus: ApplicationStatus): ApplicationStateResult {
  const statusMapping: Record<string, ApplicationState> = {
    New: ApplicationState.ApplicationReceived,
    Received: ApplicationState.ApplicationUnderReview,
    'Decl-NoResp': ApplicationState.ApplicationPending,
    Cancelled: ApplicationState.ApplicationPending,
    Declined: ApplicationState.ApplicationPending,
    Pending: ApplicationState.ApplicationPending,
    CreditApp: ApplicationState.ApprovedByUnderwriting,
    InstallApp: ApplicationState.ApplicationProcessComplete,
    Installed: ApplicationState.ApplicationProcessComplete,
  };

  if (statusMapping[applicationStatus.status]) {
    return { state: statusMapping[applicationStatus.status], statusDate: applicationStatus.statusDate };
  }

  throw new Error(`Could not parse application status: ${applicationStatus.status}`);
}
